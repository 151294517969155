var $coins = $('.js-coins')

function showLoader(question) {
  var $question = $(question)
  var $loader = $question.find('.loader')
  var format = $question.attr('data-format')
  var $nextQuestion = $question.next('.question-group')

  console.log(format)

  $question.removeClass('question-group-hidden')

  $loader.addClass('loader-show').removeClass('loader-hidden')

  setTimeout(function () {
    $loader.addClass('loader-shown')
  }, 300)

  setTimeout(function () {
    $loader.removeClass('loader-shown loader-show')
  }, 1600)

  setTimeout(function () {
    $loader.addClass('loader-hidden')

    $question.find('.question').removeClass('question-hidden')

    var position = $question.find('.question-wrapper').position()
    window.scrollTo({
      'behavior': 'smooth',
      'top': position.top - 84,
      'left': position.left
    })
  }, 2000)

  setTimeout(function () {
    $question.find('.answers').removeClass('answers-hidden')

    var position = $question.find('.question-wrapper').position()
    window.scrollTo({
      'behavior': 'smooth',
      'top': position.top - 84,
      'left': position.left
    })
  }, 3300)

  if (format === 'auto') {
    setTimeout(function () {
      showLoader($nextQuestion)
    }, 2100)
  }

  if (format === 'text') {
    setTimeout(function () {
      $question.find('.input-footer').addClass('input-footer-visible')
      $question.find('.input-footer-value').focus()
    }, 3300)
  }
}

function autorespond($question) {
  var $question = $($question)
  var $nextQuestion = $question.next('.question-group')

  showLoader($nextQuestion)
}

function respond($answer) {
  var $answer = $($answer)
  // console.log(answer)
  var response = $answer.text()
  // console.log(response)
  var $question = $answer.parents('.question-group')
  // console.log(question)
  var format = $question.attr('data-format')
  // console.log(format)
  var $nextQuestion = $question.next('.question-group')
  // console.log(nextQuestion)

  $question.find('.answers').addClass('answers-hidden')
  $question.find('.response').removeClass('response-hidden').text(response)

  // setTimeout(function () {
  //   $question.find('.response').addClass('response-sent')
  // }, 700)

  var coins = $coins.find('.navigation-coins-number').text()
  coins++
  $coins.addClass('count')
  $coins.find('.navigation-coins-number').text(coins)
  setTimeout(function () {
    $coins.removeClass('count')
  }, 500)

  showLoader($nextQuestion)
}

var responses = []

$(document).ready(function() {
  setTimeout(function () {
    showLoader($('.question-group').first())
  }, 300)
  autosize($('textarea'))


  $('.js-quick-select').click(function () {
    var $response = $(this)
    respond($response)
  })

  $('.js-multi-select').click(function () {
    var $option = $(this).parent('.answer')
    var $question = $option.parents('.question-group')
    var item = $option.text().trim()

    if ($(this).is(':checked') === true) {
      responses.push(' ' + item)
    } else {
      var deletedItem = responses.indexOf(' ' + item)
      if (deletedItem > -1) {
        responses.splice(deletedItem, 1);
      }
    }

    $question.find('.js-none-select:checked').click()

    var response = responses.toString().trim()
    console.log(response)

    var numberSelected = responses.length

    if (numberSelected > 0) {
      $question.find('.input-footer').addClass('input-footer-visible')
    } else {
      $question.find('.input-footer').removeClass('input-footer-visible')
    }

    $question.find('.js-input-footer-value').text(response)
  })

  $('.js-single-select').click(function () {
    var $option = $(this).parent('.answer')
    var $question = $option.parents('.question-group')
    var response = $option.text().trim()

    $question.find('.input-footer').addClass('input-footer-visible')
    $question.find('.js-input-footer-value').text(response)
  })

  $('.js-submit-footer-value').click(function () {
    var $response = $(this).siblings('.js-input-footer-value')
    respond($response)
    responses = []
  })

  $('.js-none-select').click(function () {
    var $option = $(this).parent('.answer')
    var $question = $option.parents('.question-group')
    var response = $option.text().trim()

    if ($(this).is(':checked') === true) {
      $(this).parents('.none-option').siblings('.js-none-deselect').find('input[type=checkbox]:checked').click()
      $question.find('.js-input-footer-value').text(response)
      $question.find('.input-footer').addClass('input-footer-visible')
    } else {
      $question.find('.input-footer').removeClass('input-footer-visible')
    }
  })
})
